import { Box, Button, LinearProgress, linearProgressClasses, Slider, TextField, Typography } from '@mui/material';

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as AnimalsIcon } from '../icons/animals.svg';
import { ReactComponent as MentalIcon } from '../icons/mental.svg';
import { ReactComponent as LgbtqIcon } from '../icons/lgbtq.svg';
import { ReactComponent as ClimateIcon } from '../icons/climate.svg';
import { ReactComponent as HomelessnessIcon } from '../icons/homelessness.svg';
import { ReactComponent as PovertyIcon } from '../icons/poverty.svg';
import { ReactComponent as GenderIcon } from '../icons/gender.svg';
import { ReactComponent as EducationIcon } from '../icons/education.svg';
import { ReactComponent as NaturalIcon } from '../icons/natural.svg';

export interface StepComponentProps {
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

const socialNetworks = [
   { id: 'animals', name: 'Protect animal rights', icon: <AnimalsIcon /> },
   { id: 'mental', name: 'Support mental health', icon: <MentalIcon /> },
   { id: 'lgbtq', name: 'Defend LGBTQ+ rights', icon: <LgbtqIcon /> },
   { id: 'climate', name: 'Fight climate crisis', icon: <ClimateIcon /> },
   { id: 'homelessness', name: 'Help the homeless', icon: <HomelessnessIcon /> },
   { id: 'poverty', name: 'End hunger', icon: <PovertyIcon /> },
   { id: 'gender', name: 'Empower gender equality', icon: <GenderIcon /> },
   { id: 'education', name: 'Enable education for all', icon: <EducationIcon /> },
   { id: 'natural', name: 'Guard natural habitats', icon: <NaturalIcon /> },
];

interface SocialNetworkCardProps {
   id: string;
   name: string;
   icon: any;
   selected?: boolean;
   onClick: () => void;
}

function SocialNetworkCard({
   id,
   name,
   icon,
   selected,
   onClick,
}: SocialNetworkCardProps): ReactElement<HTMLButtonElement> {
   return (
      <Box
         onClick={onClick}
         sx={{
            background: '#fff',
            borderRadius: '8px',
            padding: '12px 8px 12px 12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            gap: '10px',
            width: '100%',
            border: selected ? '2px solid #333' : '',
            '& > svg': {
               '& > path': {
                  fill: '#333',
                  stroke: '#333',
               },
            },
         }}
      >
         {icon}
         <Typography
            sx={{
               color: '#333',
               fontWeight: '600',
               fontSize: '14px',
               lineHeight: '16.94px',
               maxWidth: '8ch',
               overflowWrap: 'break-word',
            }}
         >
            {name}
         </Typography>
      </Box>
   );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
   height: 10,
   borderRadius: 5,
   [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#ddd',
   },
   [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#333',
   },
}));

export function SocialIssuesStep({ getHeight }: StepComponentProps): React.ReactElement {
   const [selectedNetworks, setSelectedNetworks] = useState<Array<string>>([]);
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            gap: 3.75,
         }}
      >
         <Box>
            <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
               What social issues do you care about?
            </Typography>
            <Typography sx={{ fontWeight: 500, color: '#666666', width: '100%' }} variant='h6' component='h6'>
               Choose up to three causes
            </Typography>
         </Box>
         <Box
            sx={{
               display: 'grid',
               gridTemplateColumns: '110px 110px 110px',
               gridTemplateRows: '110px 110px 110px',
               gap: '10px',
            }}
         >
            {socialNetworks.map((item: any) => {
               return (
                  <SocialNetworkCard
                     onClick={() => {
                        if (selectedNetworks.includes(item.id)) {
                           setSelectedNetworks([...selectedNetworks.filter((v) => v !== item.id)]);
                        } else if (selectedNetworks.length < 3) {
                           setSelectedNetworks([...selectedNetworks, item.id]);
                        }
                     }}
                     key={item.id}
                     {...item}
                     selected={selectedNetworks.includes(item.id)}
                  />
               );
            })}
         </Box>
         <Box sx={{ width: '100%', px: 2 }}>
            <Typography sx={{ color: '#333', fontSize: '14px', fontWeight: '500', mb: 0.75 }}>
               {selectedNetworks.length} / 3 causes added
            </Typography>
            <BorderLinearProgress variant='determinate' value={Math.round(33.3 * selectedNetworks.length)} />
         </Box>
      </Box>
   );
}
