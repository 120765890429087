/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum IMAGE {
   SLIDER = '/images/slider.png',
   MAIN_LOGO = '/images/main_bono_logo.png',
   SVG_LOGO = '/images/logo.svg',
   PRO_ISRAEL = '/images/pro_israel_logo.svg',
   PRO_ISRAEL_white = '/images/pro_israel_logo_white.svg',
   USER_IMAGE = '/images/user.png',
   CALCULATING_GIF = '/images/calculating.gif',
   CALCULATING_PNG = '/images/calculating.png',
   SMALL_SIGNATURE = '/images/bono_signature_small.png',
   BIG_SIGNATURE = '/images/bono_signature.png',

   SMALL_SEAL = '/images/bono_seal_small.png',
   BIG_SEAL = '/images/bono_seal.png',

   FEED_IMG = '/images/feed_image.png',

   STAND_WITH_ISRAEL = '/images/modal.png',
   FCK_VIOLENCE_SHARE = '/images/Fck_viol_share.png',
   HUGE_IMPACT = 'images/huge_impact.png',
   BONO_LOGO = 'images/bonoLogo.png',
   BONO_LOGO_walk = 'images/logo 26_12.svg',
   PRO_ISRAEL_LIGHT = 'images/logo-proisrael.svg',

   DONATION_ICON = 'images/donation.svg',
   GRATITUDE_VIDEO = 'https://bono-webapp-general.s3.amazonaws.com/Bono+thank+you+-+Made+with+Clipchamp.mp4',

   DONATION_BACKGROUND = '/images/creatorflow/background.png',
   DONATION_ROW1 = '/images/creatorflow/row1.png',
   DONATION_ROW2 = '/images/creatorflow/row2.png',
   DONATION_AVATAR1 = '/images/creatorflow/avatar1.jfif',
   DONATION_AVATAR2 = '/images/creatorflow/avatar2.jfif',
   DONATION_AVATAR3 = '/images/creatorflow/avatar3.jfif',
   DONATION_AVATAR4 = '/images/creatorflow/avatar4.jfif',
   DONATION_AVATAR5 = '/images/creatorflow/avatar5.jfif',
   DONATION_AVATAR6 = '/images/creatorflow/avatar6.jfif',
}
