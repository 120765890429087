import { Box, Button, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

export interface StepComponentProps {
   onNext: () => void;
   onPrev?: () => void;
}

export const colors = {
   button: '#B415FF',
} as const;

const imageData = [
   {
      id: 1,
      src: 'https://s3-alpha-sig.figma.com/img/93d9/81a2/e34750a04bc6d06803a2f8bc26011dae?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YQstDdfSiKxjL5tRIvpdOvxaVSsBJJ9Qa1uIdFAUZ4yqt4aJT8-fiLu0uUb7~FXXDKv8-y05Xw49DiT2J0BrWNBzQJlgFU61472SobEf6l6EK-e-gWGSuWKDkaCnez~brvqRjI1YwIz0UjzUcSjTxlrMNneyBMM1livZysQelpMP2xVhwbtMnWjMhHAQ1CVP2oAcxj0LAQQhNRc0RJuZ6J6nLzQZo3kB8vZF2c7hgz~R8gEx9EFbELOQjPyT5w6dhS0vhhrk7j4Zq8o9QOaadL9ylARiVxb766ediBIPtQfS1-ZtlRdlnhCgmO6wUpqg43LFPREXXl~ljTfJHNcxLw__',
      title: '2344 Meals provided for the hungry',
      tag: 'End Hunger',
      tagColor: '#FF4F44',
   },
   {
      id: 2,
      src: 'https://s3-alpha-sig.figma.com/img/85f9/3385/9092731f453abbef69523eedc1988f6e?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ngIJMwmpgo3zuU5K3RoZhBOapBG8jDo0Z7GUjlTQRNRVmHGY-ifxKeGZvIrjRdK9nw3VAFJMoXUlLwMlhTH~b2dkauyD3UIXK-AbcqgDylKT5gtyYEI36BAwXIczVxRX-6xCyIbGkz6TlZYNVi~B9xoRc4S6i8Wag7naw2nSQUHatgt75FZoaIxOqfnWD31KbpNXLiX-e4wNFnSOLhJfnhnxYPmuYXPtKROumQTDNWJeVjKHnSrmFYd4EDSszlqvyl3F4OdKXkEUjdRR1DlEQLYAUBd8iGNIij1OIhXtdYW8qkqdGquFDg7awns5RLeJ3wyA22pyY63tKD8I5ddToQ__',
      title: '31 Farm animals rescued',
      tag: 'Protect animal rights',
      tagColor: '#B415FF',
   },
   {
      id: 3,
      src: 'https://s3-alpha-sig.figma.com/img/a858/ec78/4f979ac158e251d32fc9e0ac940b9511?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Lxvq9QqBmginrTetKcZ3cj-CPOMkDPoumRx442e4Gs7DYOZ8cf0VmBcZVGlwg4RFGCmjctUZFBfrkZ3DTL8s2cFs98qiewNoQpEmddtV0S8BlaQfbArV1Fqs78rkLcc4YqsIoTe0C0bnSKFjT5IKf8~8ZrzvL7aekWDZ7ykia9JAOMYK49vIiktxZ2lVNvCO-bx45N1NDShdBErgjCjWlPBDwViXtTKE-GqgwX9Lczea7rXvVh8soi10Ry-O6Ktb9p-kA7lN8NshP4KSDbzQrki1J0ga-pyKB~mGs5c-bwDJhYcHP-I~ja9Mhpq2-tR7Uaskk3z2zstCdd0QL~w2KA__',
      title: '90 Trees planted for reforestation',
      tag: 'Fight climate crisis',
      tagColor: '#2BA096',
   },
];

export function PersonalImpactStep({ onNext, onPrev }: StepComponentProps): React.ReactElement {
   return (
      <Box
         sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
            Your personal yearly impact could be:
         </Typography>
         <Box sx={{ width: '310px', height: '350px', display: { sm: 'none', xs: 'block' } }}>
            <Swiper spaceBetween={50} slidesPerView={1} loop>
               {imageData.map((item) => (
                  <SwiperSlide key={item.id}>
                     <Box
                        sx={{
                           position: 'relative',
                           width: { xs: '310px', sm: '220px' },
                           height: { xs: '310px', sm: '220px' },
                        }}
                     >
                        <Box
                           sx={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px',
                              position: 'absolute',
                           }}
                           src={item.src}
                           alt={item.title}
                           component='img'
                        />
                        <Box
                           sx={{
                              position: 'absolute',
                              background: item.tagColor,
                              color: '#fff',
                              zIndex: '2',
                              top: '17px',
                              left: '23px',
                              padding: '2px 8px',
                              borderRadius: '2px',
                              fontWeight: '700',
                              fontSize: '12px',
                              lineHeight: '18px',
                           }}
                        >
                           {item.tag}
                        </Box>
                        <Typography
                           sx={{
                              position: 'absolute',
                              color: '#fff',
                              zIndex: '2',
                              bottom: '13px',
                              left: '15px',
                              right: '25px',
                              fontWeight: '700',
                              fontSize: { xs: '36px', sm: '24px' },
                              lineHeight: { xs: '36px', sm: '24px' },
                           }}
                        >
                           {item.title}
                        </Typography>
                        <Box
                           sx={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px',
                              position: 'absolute',
                              zIndex: '1',
                              background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)',
                           }}
                        />
                     </Box>
                  </SwiperSlide>
               ))}
            </Swiper>
            <Box
               sx={{
                  background: '#B415FF1A',
                  border: '1px solid #0000001A',
                  padding: '20px 30px',
                  fontSize: '20px',
                  lineHeight: '24px',
                  fontWeight: '500',
                  mt: 3,
                  borderRadius: '10px',
               }}
            >
               You can also make up to $1,560 a year in personal earnings while doing good 😊
               <br />
               <a href='#'>Learn more</a>
            </Box>
         </Box>
         <Box
            sx={{
               width: `${imageData.length * 240 + 220}px`,
               gap: '20px',
               height: '220px',
               display: { sm: 'flex', xs: 'none' },
            }}
         >
            {imageData.map((item) => (
               <Box
                  key={item.id}
                  sx={{
                     position: 'relative',
                     width: { xs: '310px', sm: '220px' },
                     height: { xs: '310px', sm: '220px' },
                  }}
               >
                  <Box
                     sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        position: 'absolute',
                     }}
                     src={item.src}
                     alt={item.title}
                     component='img'
                  />
                  <Box
                     sx={{
                        position: 'absolute',
                        background: item.tagColor,
                        color: '#fff',
                        zIndex: '2',
                        top: '17px',
                        left: '23px',
                        padding: '2px 8px',
                        borderRadius: '2px',
                        fontWeight: '700',
                        fontSize: '12px',
                        lineHeight: '18px',
                     }}
                  >
                     {item.tag}
                  </Box>
                  <Typography
                     sx={{
                        position: 'absolute',
                        color: '#fff',
                        zIndex: '2',
                        bottom: '13px',
                        left: '15px',
                        right: '25px',
                        fontWeight: '700',
                        fontSize: { xs: '36px', sm: '24px' },
                        lineHeight: { xs: '36px', sm: '24px' },
                     }}
                  >
                     {item.title}
                  </Typography>
                  <Box
                     sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        position: 'absolute',
                        zIndex: '1',
                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)',
                     }}
                  />
               </Box>
            ))}

            <Box
               sx={{
                  background: '#B415FF1A',
                  border: '1px solid #0000001A',
                  padding: '20px 30px',
                  width: '220px',
                  fontSize: '20px',
                  lineHeight: '24px',
                  fontWeight: '700',
                  color: '#333',
               }}
            >
               ...and you can make up to $1,200/year in personal earnings while doing good 😊
            </Box>
         </Box>

         <Box
            sx={{
               position: { xs: 'fixed', sm: 'relative' },
               bottom: { xs: 0 },
               height: { xs: '98px', sm: 'unset' },
               borderTop: { xs: '1px solid #00000016', sm: 'unset' },
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               width: { xs: '100%', sm: '100%' },
            }}
         >
            <Button
               sx={{ mt: { sm: 5.25, width: '100%' }, mx: { xs: '30px', sm: 'unset' }, justifyContent: 'center' }}
               className='creatorFlow'
               variant='contained'
               size='large'
               color='black'
               onClick={onNext}
            >
               Lets Start{'->'}
            </Button>
         </Box>
      </Box>
   );
}
