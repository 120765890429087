import { Box, Typography } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { IMAGE } from 'enums/images';
import { customOptions } from 'styles/theme';

export interface StepComponentProps {
   isMobile: boolean;
   onNext: () => void;
   onPrev?: () => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function CalculatingStep({ isMobile, onNext, onPrev }: StepComponentProps): React.ReactElement {
   useLayoutEffect(() => {
      setTimeout(() => {
         onNext();
      }, 7000);
   }, []);

   return (
      <Box
         sx={{
            height: '100%',
            width: '400px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            paddingLeft: '24px',
            paddingRight: '24px',
            margin: `0 -${customOptions.rootSpacing.paddingLeft}`,
            backgroundColor: '#ecf3ee',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
            Hold on, calculating your predicted impact & benefits
         </Typography>
         <Box
            sx={{
               width: { xs: '140px', sm: '100%' },
               height: { xs: '200px', sm: 'unset' },
               marginTop: { xs: '70px', sm: 'unset' },
               borderRadius: '10px',
            }}
            src={isMobile ? IMAGE.CALCULATING_PNG : IMAGE.CALCULATING_GIF}
            alt='Calculating'
            component='img'
         />
      </Box>
   );
}
