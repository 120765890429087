import { Box } from '@mui/material';

import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FloatingUserButton } from 'components/floatingUser/floatingUser';
import { BackIconButton } from 'components/common';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { IMAGE } from '../../enums/images';
import { routes } from '../../routes/routes';

export function Header(): React.ReactElement {
   const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';
   const navigate = useNavigate();
   const location = useLocation();

   const hideBackButton = ['/share', '/'];

   const handleBack = () => {
      if (location.pathname === routes().portfolio) {
         navigate(routes().causes);
      } else {
         navigate(-1);
      }
   };

   return (
      <Box
         component='nav'
         width='100%'
         height={{ xs: '88px', sm: '60px' }}
         display='flex'
         sx={{
            py: { xs: 2.5, sm: 2.5 },
            px: { sm: 3.75 },
            background: { xs: 'none', sm: '#333333' },
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 500,
         }}
      >
         <BackIconButton
            sx={{
               position: { xs: 'relative', sm: 'fixed' },
               display: {
                  xs: hideBackButton.includes(location.pathname) ? 'none' : 'flex',
                  sm: hideBackButton.includes(location.pathname) ? 'none' : 'flex',
               },
               justifyContent: 'center',
               alignItems: 'center',
               top: { xs: 0, sm: '90px' },
               left: { xs: 0, sm: '40px' },
            }}
            onClick={handleBack}
         >
            <Icon name={ENUM_ICON.ARROW_BACK} />
         </BackIconButton>

         <Box
            component='img'
            alt='Stand With Israel'
            width='60px'
            height='40px'
            sx={{
               width: { xs: '77px', sm: '60px' },
               height: { xs: '52px', sm: '40px' },
               aspectRatio: '1/1',
               justifyContent: 'center',
               display: { xs: hideBackButton.includes(location.pathname) ? 'block' : 'none', sm: 'block' },
            }}
            src={useGlobalStyle ? IMAGE.MAIN_LOGO : IMAGE.PRO_ISRAEL_LIGHT}
         />

         {location.pathname === 'signIn' || location.pathname === 'checkYourEmail' ? '' : <FloatingUserButton />}
      </Box>
   );
}

export function PublicLayout(): React.ReactElement {
   return (
      <Box
         sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            px: { xs: 2.5, sm: 0 },
            height: '100dvh',
         }}
         component='main'
      >
         <Header />
         <Box
            sx={{
               pt: { xs: 0, sm: 5 },
               position: 'relative',
               display: 'flex',
               flex: 1,
               alignSelf: 'center',
               width: { xs: '100%', sm: '400px' },
               overflow: { xs: 'auto', sm: 'visible' },
               '&::-webkit-scrollbar': { display: 'none' },
            }}
         >
            <Outlet />
         </Box>
      </Box>
   );
}

export function NoLayout(): React.ReactElement {
   return <Outlet />;
}
