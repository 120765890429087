import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, CardMedia } from '@mui/material';
import { Icon } from 'components/icon';

import { ButtonFurther, LoadingIcon, PageSubLabel } from 'components/common';
import { IMAGE } from 'enums/images';
import { ENUM_ICON } from 'enums/icons';
import { routes } from 'routes/routes';
import { customOptions } from 'styles/theme';

export function CreatorGratitudePage() {
   const navigate = useNavigate();
   const { t } = useTranslation();

   const [loading, setLoading] = useState(false);

   const handleSubmit = () => {
      setLoading(true);
      navigate(routes().profile);
   };

   return (
      <Box
         sx={{
            flex: '1',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '20px' },
            backgroundColor: '#B415FF1A',
            justifyContent: { xs: 'space-between', sm: 'space-between' },
            marginLeft: `-${customOptions.rootSpacing.paddingLeft}`,
            marginRight: `-${customOptions.rootSpacing.paddingRight}`,
         }}
      >
         <Box
            component='div'
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            marginTop='70px'
         >
            <Box
               component='img'
               alt='Donate'
               width={81}
               height={70}
               marginTop='4px'
               marginBottom='20px'
               src={`/${IMAGE.DONATION_ICON}`}
            />
            <PageSubLabel
               fontSize={34}
               fontWeight='900 !important'
               color='#333333'
               lineHeight='38px !important'
               textAlign='center'
            >
               {t('Thanks for subscribing!')}
            </PageSubLabel>
            <Box
               component='video'
               autoPlay
               muted
               controls
               width='350px'
               height='350px'
               sx={{
                  width: '100%',
                  height: '350px',
                  padding: '30px 20px 0px 20px',
               }}
            >
               <source src={IMAGE.GRATITUDE_VIDEO} type='video/mp4' />
            </Box>
         </Box>
         <Box
            sx={{
               position: { xs: 'relative', sm: 'relative' },
               bottom: { xs: 0, sm: 0 },
               display: 'flex',
               width: '100%',
               padding: '0 30px 0 30px',
            }}
         >
            <ButtonFurther
               sx={{
                  backgroundColor: '#262627',
                  marginBottom: 4,
                  width: { lg: '100%' },
                  mx: { lg: 'auto' },
               }}
               endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
               onClick={handleSubmit}
            >
               {loading ? <LoadingIcon /> : `${t('common:donation.buttonCTA')}`}
            </ButtonFurther>
         </Box>
      </Box>
   );
}
