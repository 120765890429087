import React, { useEffect, useState } from 'react';
import { Box, Typography, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
   getStripe,
   //  authState
} from 'store/auth/auth.slice';
import { PageMainLabel, ButtonFurther, LoadingIcon, PageSubLabel } from 'components/common';
import { causesState } from 'store/causes/causes.slice';

import { tryParseInt } from 'utils/utils';
import { IMAGE } from 'enums/images';
import { CenteredWrapper, ActionItemsWrapper, IconWrapper, AmountBox } from './styles';

export function DonationPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { selectedCausesList } = useAppSelector(causesState);
   const startAmount = tryParseInt(process.env.REACT_APP_START_AMOUNT, 10);
   const minAmount = tryParseInt(process.env.REACT_APP_MIN_AMOUNT, 5);
   const changeAmount = tryParseInt(process.env.REACT_APP_CHANGE_AMOUNT, 5);
   const maxAmount = tryParseInt(process.env.REACT_APP_MAX_AMOUNT, 1000);
   const { t } = useTranslation();

   const [amount, setAmount] = useState(startAmount);
   const [loading, setLoading] = useState(false);
   const [checked, setChecked] = useState(false);

   const animationStartPos = 38;

   const handleSubmit = () => {
      setLoading(true);
      analytics?.track('begin_checkout', {
         causes: `[${selectedCausesList.map((cause) => cause.id).join()}]`,
         value: amount,
         currency: 'USD',
      });
      dispatch(getStripe({ money: amount, serviceFee: 0 }));
   };

   const handleCheckboxChange = () => {
      setChecked(!checked);
   };

   const changeAmountButtonClick = (increase: boolean) => {
      let multi = Math.floor(amount / changeAmount);
      // eslint-disable-next-line no-plusplus
      increase ? multi++ : multi--;
      let finalValue = multi * changeAmount;
      if (finalValue < minAmount) {
         finalValue = minAmount;
      } else if (finalValue > maxAmount) {
         finalValue = maxAmount;
      }
      setAmount(finalValue);
   };

   useEffect(() => {
      document.title = `Donation | ${t('siteTitleName')}`;
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            // width: '400px',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '20px' },
            justifyContent: { xs: 'space-between', sm: 'space-between' },
         }}
      >
         <Box component='div' width='100%' display='flex' flexDirection='column'>
            <PageMainLabel>{t('common:donation.heading')}</PageMainLabel>
            <Box
               sx={{
                  width: 350,
                  height: 213,
                  backgroundColor: '#B415FF1A',
                  borderRadius: '6px',
                  padding: '16px',
               }}
            >
               <Box
                  component='img'
                  alt='Donate'
                  width={81}
                  height={70}
                  marginTop='4px'
                  marginBottom='16px'
                  src={IMAGE.DONATION_ICON}
               />
               <PageSubLabel fontSize={24} fontWeight='700 !important' color='#333333' lineHeight='29.05px !important'>
                  {t('Bono will donate $5 every month on your behalf over the next 12 months')}
               </PageSubLabel>
            </Box>
            <Box
               sx={{
                  display: 'flex',
                  marginTop: '20px',
               }}
            >
               <Checkbox
                  style={{ color: '#333333', paddingLeft: 0 }}
                  checked={checked}
                  onChange={handleCheckboxChange}
               />
               <PageSubLabel fontSize={18}>
                  {t('Top it up with a few more dollars a month for good karma!')}
                  <br />
                  {t('(you can cancel anytime)')}
               </PageSubLabel>
            </Box>

            <CenteredWrapper>
               <motion.div
                  initial={{ opacity: 0, y: animationStartPos }}
                  animate={checked ? { opacity: 1, y: animationStartPos - 20 } : { opacity: 0, y: animationStartPos }}
                  transition={{ duration: 0.3 }}
               >
                  <ActionItemsWrapper pb={{ xs: 3.5, sm: 3 }} pt={{ xs: 3.5, sm: 7 }} width={{ sm: '300px' }} mx='auto'>
                     <IconWrapper
                        onClick={() => {
                           analytics?.track('amount_change', {
                              action: 'minus',
                              amount: amount - changeAmount,
                           });
                           changeAmountButtonClick(false);
                        }}
                        disabled={amount === minAmount}
                     >
                        <Icon name={ENUM_ICON.MINUS} />
                     </IconWrapper>
                     <AmountBox>
                        <Typography component='h6' className='SubscriptionAmount' style={{ fontWeight: 900 }}>
                           <p>$</p> <span>{amount}</span>
                        </Typography>
                     </AmountBox>
                     <IconWrapper
                        onClick={() => {
                           analytics?.track('amount_change', {
                              action: 'plus',
                              amount: amount + changeAmount,
                           });

                           changeAmountButtonClick(true);
                        }}
                        disabled={amount === maxAmount}
                     >
                        <Icon name={ENUM_ICON.PLUS} />
                     </IconWrapper>
                  </ActionItemsWrapper>
               </motion.div>
            </CenteredWrapper>
         </Box>
         <Box
            sx={{
               position: { xs: 'relative', sm: 'relative' },
               bottom: { xs: 0, sm: 0 },
               display: 'flex',
               width: '100%',
            }}
         >
            <ButtonFurther
               sx={{
                  backgroundColor: '#262627',
                  marginBottom: 4,
                  width: { lg: '100%' },
                  mx: { lg: 'auto' },
               }}
               endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
               onClick={handleSubmit}
            >
               {loading ? <LoadingIcon /> : `${t('common:donation.buttonCTA')}`}
            </ButtonFurther>
         </Box>
      </Box>
   );
}
