import { Box, Button, Slider, TextField, Typography } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledInput } from '../textField/CreatorFlowTextField';

export interface StepComponentProps {
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

const marks = [
   {
      value: 0,
      label: '1K',
      name: '1,000',
   },
   {
      value: 25,
      label: '10K',
      name: '10,000',
   },
   {
      value: 50,
      label: '50K',
      name: '50,000',
   },
   {
      value: 75,
      label: '100K',
      name: '100,000',
   },
   {
      value: 100,
      label: '500K+',
      name: '500,000+',
   },
];

function valuetext(value: number) {
   return `${value}°C`;
}

export function FollowersStep({ getHeight }: StepComponentProps): React.ReactElement {
   const [value, setValue] = useState(0);
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.
   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
            How many followers do you have?
         </Typography>
         <Box
            sx={{
               width: '100%',
               padding: '0px 12px',
               mt: 1.25,
            }}
         >
            <Slider
               sx={{
                  '& .MuiSlider-rail': {
                     color: '#B415FF1A',
                     opacity: 1,
                  },
                  '& .MuiSlider-thumb': {
                     color: '#333',
                  },
                  '& .MuiSlider-thumb:hover': {
                     boxShadow: 'none',
                  },
                  '& .MuiSlider-track': {
                     color: '#333',
                  },
               }}
               aria-label='Restricted values'
               defaultValue={0}
               getAriaValueText={valuetext}
               step={null}
               valueLabelDisplay='auto'
               marks={marks}
               color='purple'
               onChange={(v) => setValue((v.target as any)?.value ?? 0)}
            />
         </Box>
         <Box
            sx={{
               width: '100%',
            }}
         >
            <StyledInput readOnly value={marks.find((v: any) => v.value === value)?.name} type='text' />
         </Box>
      </Box>
   );
}
// import { Box, LinearProgress, Slider, TextField, Typography } from '@mui/material';
// import React, { useEffect, useRef, useState } from 'react';

// export interface StepComponentProps {
//    getHeight: (height: number) => void;
// }

// export const colors = {
//    button: '#B415FF',
// } as const;

// const MAX_FOLLOWERS = 5000000; // Set a higher maximum limit for followers

// // Helper function to format numbers with commas
// const formatNumber = (num: number) => {
//    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };

// // Marks for the slider
// const marks = [
//    { value: 1000, label: '1K' },
//    { value: 10000, label: '10K' },
//    { value: 50000, label: '50K' },
//    { value: 100000, label: '100K' },
//    { value: 500000, label: '500K+' },
// ];

// export function FollowersStep({ getHeight }: StepComponentProps): React.ReactElement {
//    const [value, setValue] = useState(1000); // Default to the first mark
//    const containerRef = useRef<HTMLDivElement | null>(null);

//    useEffect(() => {
//       if (containerRef.current) {
//          const { height } = containerRef.current.getBoundingClientRect();
//          getHeight(height);
//       }
//    }, []); // Runs once on mount.

//    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//       const inputValue = event.target.value.replace(/,/g, '');
//       const parsedValue = Number(inputValue);
//       if (!isNaN(parsedValue) && parsedValue >= 0) {
//          setValue(parsedValue);
//       }
//    };

//    const handleSliderChange = (event: Event, newValue: number | number[]) => {
//       const index = newValue as number;
//       setValue(marks[index].value);
//    };

//    const progressPercentage = (value / MAX_FOLLOWERS) * 100;

//    const getSliderIndex = (val: number) => {
//       const index = marks.findIndex((mark) => mark.value >= val);
//       return index === -1 ? marks.length - 1 : index;
//    };

//    return (
//       <Box
//          ref={containerRef}
//          sx={{
//             width: '100%',
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             justifyContent: 'start',
//             paddingTop: '120px',
//             gap: 3.75,
//          }}
//       >
//          <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
//             How many followers do you have?
//          </Typography>
//          <Box sx={{ width: '100%' }}>
//             <Slider
//                value={getSliderIndex(value)}
//                onChange={handleSliderChange}
//                aria-labelledby='followers-slider'
//                valueLabelDisplay='auto'
//                step={1}
//                min={0}
//                max={marks.length - 1}
//                marks={marks}
//                color='purple'
//                sx={{ marginBottom: 2 }}
//             />
//             <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                {marks.map((mark) => (
//                   <Typography key={mark.value} variant='caption'>
//                      {mark.label}
//                   </Typography>
//                ))}
//             </Box>
//          </Box>
//          <Box sx={{ width: '100%' }}>
//             <TextField
//                type='text'
//                value={formatNumber(value)}
//                onChange={handleInputChange}
//                placeholder='Enter number of followers'
//                fullWidth
//                inputProps={{ min: 0 }}
//             />
//          </Box>
//       </Box>
//    );
// }
