/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ButtonFurther } from 'components/common';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { authState } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';
import { FloatingUserButton } from 'components/floatingUser/floatingUser';
import { IMAGE } from 'enums/images';

export function ValidatorAnimation() {
   const animateData = {
      logoPos: 130,
      welcomeTextPos: 172,
      titlePos: 50,
   };
   const labelArray = [
      '',
      'Analyzing your causes...',
      'Finding matching nonprofits...',
      'Checkout your giving portfolio...',
   ];
   const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';
   const navigate = useNavigate();
   const location = useLocation();
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const [isShowCauses, setIsShowCauses] = useState(false);
   const [isFirstAnimateFinish, setIsFirstAnimateFinish] = useState(false);
   const [activeCause, setActiveCause] = useState(-2);
   const [selectedCause, setSelectedCause] = useState(-1);
   const [currentTitle, setCurrentTitle] = useState(0);
   const [activatedColors, setActivatedColors] = useState(Array(4).fill('white')); // Initialize all colors as white
   const [isVertical, setIsVertical] = useState(true); // Start with vertical layout
   const [isVerticalAnimateFinish, setIsVerticalAnimateFinish] = useState(false);
   const { loggedUserData } = useAppSelector(authState);
   const [hasSubscription, setHasSubscription] = React.useState<boolean | undefined>(false);

   useEffect(() => {
      setHasSubscription(loggedUserData?.subscription?.status === 'active');
   }, [loggedUserData]);

   useEffect(() => {
      const interval = setInterval(() => {
         setActiveCause((prev) => {
            const nextIndex = prev + 1;
            prev === 2 && setCurrentTitle(3);
            if (prev === 3) {
               clearInterval(interval);
               setIsVertical(false);

               return prev + 1; // Return the same index to stop further updates
            }
            setActivatedColors((prevColors) => {
               const newColors = [...prevColors];
               newColors[nextIndex] = loggedUserData?.userCauses[nextIndex]?.cause.impactBackground; // Set the color for the next active div
               return newColors;
            });
            return nextIndex;
         });
      }, 3500); // Change color every 2 seconds

      return () => clearInterval(interval); // Cleanup on unmount
   }, []);

   const handleClickButtonEvent = (index: number) => {
      index === selectedCause ? setSelectedCause(5) : setSelectedCause(index);
   };

   const divStyle = (index: any) => ({
      backgroundColor: activatedColors[index],
      boxShadow: activeCause === index ? '0px 0px 0px 5px black' : '0px 0px 0px 1px #33333350',
      color: activatedColors[index] === 'white' ? '#33333350' : '#fff',
   });

   useEffect(() => {
      const timer = setTimeout(() => {
         setIsFirstAnimateFinish(true);
      }, 2000);
      return () => clearTimeout(timer);
   }, []);

   useEffect(() => {
      let timer: NodeJS.Timeout | undefined;
      if (isFirstAnimateFinish) {
         timer = setTimeout(() => {
            setIsShowCauses(true);
         }, 1000);
      }
      return () => {
         if (timer) {
            clearTimeout(timer);
         }
      };
   }, [isFirstAnimateFinish]);

   const handleAnimateStart = () => {
      currentTitle === 0 && setCurrentTitle(1);
   };

   const differentButtonAction = () => {
      navigate(routes().donation);
   };

   return (
      <Box
         sx={{
            display: 'flex',
            fontFamily: 'Inter',
            height: '100vh',
            flexDirection: 'column',
            borderWidth: '2px',
         }}
      >
         {isMobile && (
            <div
               style={{
                  display: 'flex',
                  padding: '34px',
                  backgroundColor: '#EBE9E5',
               }}
            >
               <motion.img
                  src={useGlobalStyle ? IMAGE.MAIN_LOGO : IMAGE.PRO_ISRAEL_LIGHT}
                  alt='Stand With Israel'
                  style={{
                     margin: '0px auto',
                  }}
                  // initial={{ opacity: 0, y: animateData.logoPos }}
                  // animate={isFirstAnimateFinish ? { opacity: 1, y: 0 } : { opacity: 1, y: animateData.logoPos - 20 }}
                  // transition={{ duration: 1.2 }}
               />
               <Box
                  sx={{
                     position: 'absolute',
                     right: 25,
                     top: 40,
                  }}
               >
                  {location.pathname === 'signIn' || location.pathname === 'checkYourEmail' ? (
                     ''
                  ) : (
                     <FloatingUserButton />
                  )}
               </Box>
            </div>
         )}
         <motion.h1
            style={{
               display: 'absolute',
               fontSize: '34px',
               fontWeight: '900',
               lineHeight: '38px',
               textAlign: 'center',
               margin: '0px 50px',
            }}
            initial={{ opacity: 0, y: animateData.welcomeTextPos + 10 }}
            animate={
               isFirstAnimateFinish
                  ? { opacity: 0, y: animateData.welcomeTextPos - 10, display: 'none' }
                  : { opacity: 1, y: animateData.welcomeTextPos }
            }
            transition={{ duration: 1 }}
            onAnimationComplete={handleAnimateStart}
            // onAnimationStart={handleAnimateFinished}
         >
            Thanks for confirming your email!
         </motion.h1>
         <AnimatePresence mode='wait'>
            <motion.p
               style={{
                  display: 'absolute',
                  fontSize: '34px',
                  fontWeight: '900',
                  lineHeight: '38px',
                  textAlign: 'center',
                  margin: '0px 50px',
               }}
               key={currentTitle || 'empty'}
               initial={{ y: animateData.titlePos + 10, opacity: 0 }}
               animate={{ y: animateData.titlePos, opacity: 1 }}
               exit={{ y: animateData.titlePos - 10, opacity: 0 }}
               transition={{ duration: 0.5, delay: currentTitle === 2 ? 0.2 : 0.8 }}
            >
               {currentTitle ? labelArray[currentTitle] : ''}
            </motion.p>
         </AnimatePresence>
         <motion.div
            className='w-full'
            initial={{ opacity: 0, y: 20 }}
            animate={isShowCauses ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.3, delay: 2 }}
            onAnimationComplete={() => setCurrentTitle(2)}
         >
            <motion.div
               className='w-full flex gap-3 h-fit justify-center p-16 items-center'
               style={{
                  display: 'flex',
                  flexDirection: isVertical ? 'row' : 'column',
                  gap: '0.75rem',
                  height: 'fit-content',
                  justifyContent: 'center',
                  padding: '30px 20px',
                  alignItems: 'center',
                  marginTop: animateData.titlePos,
                  width: '100%',
               }}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 2 }}
            >
               {loggedUserData?.userCauses?.map((_: any, index: any) => (
                  <motion.div
                     key={loggedUserData?.userCauses[index]?.cause.title}
                     style={{
                        borderRadius: '8px',
                        width: isVertical ? '110px' : '100%',
                        userSelect: 'none',
                        padding: '12px',
                        fontSize: '14px',
                        height: isVerticalAnimateFinish ? '80px' : '117px',
                        ...divStyle(index),
                        transition: 'background-color 0.5s ease, border 0.5s ease, box-shadow 0.5s ease',
                     }}
                     initial={{ height: isVerticalAnimateFinish ? '80px' : '117px' }}
                     animate={
                        selectedCause === index
                           ? { height: isVerticalAnimateFinish ? '143px' : '180px' }
                           : { height: isVerticalAnimateFinish ? '80px' : '117px' }
                     }
                     transition={{ duration: 0.3 }}
                     onClick={() => handleClickButtonEvent(isVertical ? -1 : index)}
                     layout
                  >
                     <motion.div
                        initial={{ display: 'block' }}
                        animate={isVerticalAnimateFinish && { display: 'flex' }}
                        transition={{ duration: 0.5 }}
                     >
                        <div
                           dangerouslySetInnerHTML={{
                              __html:
                                 activatedColors[index] === 'white'
                                    ? loggedUserData?.userCauses[index]?.cause.impactImage.replace(
                                         /fill="[^"]*"/g,
                                         `fill="#33333350"`
                                      )
                                    : loggedUserData?.userCauses[index]?.cause.impactImage.replace(
                                         /fill="[^"]*"/g,
                                         `fill="white"`
                                      ),
                           }}
                           style={{
                              width: isVertical ? '30px' : '40px',
                              height: isVertical ? '30px' : '40px',
                              transform: isVertical ? 'scale(0.5)' : 'scale(0.7)',
                              transformOrigin: 'top left',
                              paddingBottom: '10px',
                              padding: isVerticalAnimateFinish ? '8px 0px 8px 0px' : '0px 0px 10px 0px',
                           }}
                        />
                        <div style={isVerticalAnimateFinish ? { padding: '8px 0px 8px 10px' } : { paddingTop: '10px' }}>
                           <p
                              style={{
                                 fontSize: isVertical ? '14px' : '20px',
                                 lineHeight: isVertical ? '17px' : '24.2px',
                                 fontWeight: 600,
                              }}
                           >
                              {loggedUserData?.userCauses[index]?.cause.title}
                           </p>
                           {!isVertical && <p style={{ fontSize: 16 }}>{'3 Non profits ->'}</p>}
                        </div>
                     </motion.div>
                     <motion.div
                        initial={{ opacity: 0, display: 'none' }}
                        style={{
                           display: 'flex',
                           padding: '10px 10px 0px 0px',
                        }}
                        animate={
                           selectedCause === index ? { opacity: 1, display: 'flex' } : { opacity: 0, display: 'none' }
                        }
                        exit={{ opacity: 0, display: 'none' }}
                        transition={{
                           duration: selectedCause === index ? 0.3 : 0,
                           delay: selectedCause === index ? 0.1 : 0,
                        }}
                     >
                        {loggedUserData?.userCauses[selectedCause]?.cause?.charities?.map((charity: any) => (
                           <div
                              key={charity.title}
                              style={{
                                 display: 'flex',
                                 borderRadius: '8px',
                                 marginRight: '10px',
                                 justifyContent: 'space-between',
                                 backgroundColor: '#fff',
                                 alignItems: 'center',
                              }}
                           >
                              <img
                                 src={selectedCause > -1 && selectedCause < 3 ? charity.icon : ''}
                                 height={50}
                                 alt='select-cause'
                              />
                           </div>
                        ))}
                     </motion.div>
                  </motion.div>
               ))}
            </motion.div>
            <motion.div
               initial={{ opacity: 0 }}
               animate={!isVertical && { opacity: 1 }}
               transition={{ duration: 0.5, delay: 0.5 }}
               onAnimationComplete={() => setIsVerticalAnimateFinish(true)}
            />
            {!isVertical && (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={!isVertical && { opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.7 }}
               >
                  <p
                     style={{
                        fontSize: '18px',
                        padding: '0px 20px',
                        fontWeight: 500,
                        color: '#666666',
                     }}
                  >
                     Expand each cause to see your trusted charities. Tap a logo to learn more.
                  </p>
                  <p
                     style={{
                        margin: '20px',
                        fontSize: '18px',
                        padding: '0px 20px',
                        fontWeight: 500,
                        textDecoration: 'underline',
                        textAlign: 'center',
                     }}
                  >
                     Edit causes
                  </p>
               </motion.div>
            )}
         </motion.div>
         <AnimatePresence mode='wait'>
            <motion.h1
               style={{
                  display: 'absolute',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '17px',
                  padding: '0px 20px',
                  textAlign: 'center',
                  margin: '0px 0px',
               }}
               key={activeCause || 'empty'}
               initial={{ x: 20, opacity: 0 }}
               animate={activeCause > -1 && activeCause < 3 && { x: 0, opacity: 1 }}
               exit={{ x: -20, opacity: 0 }}
               transition={{ duration: 0.5, delay: 0.5 }}
            >
               {loggedUserData?.userCauses[activeCause]?.cause?.charities?.map((charity: any, index: number) => (
                  <div
                     key={charity.title}
                     style={{
                        display: 'flex',
                        borderRadius: '8px',
                        borderWidth: '1px',
                        margin: '10px 0px',
                        color: '#666666',
                        padding: '2px 10px',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff',
                        alignItems: 'center',
                     }}
                  >
                     <p>{activeCause > -1 && activeCause < 3 ? charity.title : ''}</p>
                     <img
                        src={activeCause > -1 && activeCause < 3 ? charity.icon : ''}
                        height={50}
                        alt='nonprofit-preview'
                     />
                  </div>
               ))}
            </motion.h1>
         </AnimatePresence>
         <motion.div
            initial={{ opacity: 0 }}
            animate={!isVertical && { opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.7 }}
            style={{
               borderTopWidth: '1px',
               borderColor: '#000000',
               padding: '20px',
            }}
         >
            <Box
               sx={{
                  position: { xs: 'relative', sm: 'relative' },
                  display: 'flex',
                  bottom: { xs: 0 },
                  width: '100%',
               }}
            >
               <ButtonFurther
                  sx={{
                     marginBottom: 4,
                     width: { sm: '100%' },
                     mx: { sm: 'auto' },
                  }}
                  endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />}
                  onClick={differentButtonAction}
               >
                  Take Action
               </ButtonFurther>
            </Box>
         </motion.div>
      </Box>
   );
}
