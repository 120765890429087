import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import React, { useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FloatingUserButton } from 'components/floatingUser/floatingUser';
import { BackIconButton } from 'components/common';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { IMAGE } from '../../../enums/images';

export function Header(): React.ReactElement {
   const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';
   const navigate = useNavigate();
   const location = useLocation();

   const hideBackButton = ['/share', '/'];

   return (
      <Box
         component='nav'
         width='100%'
         height={{ xs: '88px', sm: '60px' }}
         display={{ xs: 'none', sm: 'flex' }}
         sx={{
            py: { xs: 2.5, sm: 2.5 },
            px: { sm: 3.75 },
            background: { xs: 'none', sm: '#333333' },
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 500,
         }}
      >
         <Box
            component='img'
            alt='Stand With Israel'
            width='60px'
            height='40px'
            sx={{
               width: { xs: '77px', sm: '60px' },
               height: { xs: '52px', sm: '40px' },
               aspectRatio: '1/1',
               justifyContent: 'center',
               display: { xs: hideBackButton.includes(location.pathname) ? 'block' : 'none', sm: 'block' },
            }}
            src={useGlobalStyle ? IMAGE.MAIN_LOGO : IMAGE.PRO_ISRAEL_LIGHT}
         />

         {location.pathname === 'signIn' || location.pathname === 'checkYourEmail' ? '' : <FloatingUserButton />}
      </Box>
   );
}

const logoColors = keyframes`
  0% { fill: #0279CB; }
  25% { fill: #2BA096; }
  50% { fill: #FFCAC7; }
  75% { fill: #F4D5B2; }
  100% { fill: #E5CAB4; }
`;

const backgroundColors = keyframes`
  0% { background-color: #B3D7EF; }
  25% { background-color: #BFE2DF; }
  50% { background-color: #FF4F44; }
  75% { background-color: #D97400; }
  100% { background-color: #A74D06; }
`;

const AnimatedBox = styled(Box)`
   animation: ${backgroundColors} 4s steps(1) infinite;
`;

const AnimatedLogoContainer = styled.div`
   & *:not(#whitePart) {
      animation: ${logoColors} 4s steps(1) infinite;
   }
`;

export function CreatorFlowLoading(): React.ReactElement {
   return (
      <AnimatedBox
         sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '10000',
         }}
      >
         <AnimatedLogoContainer>
            <svg width='150' height='102' viewBox='0 0 150 102' fill='none' xmlns='http://www.w3.org/2000/svg'>
               <g clipPath='url(#clip0_10119_14936)'>
                  <path d='M150 5.5868L142.425 0L0 29.6376V95.175L7.57455 101.235L150 71.2009V5.5868Z' fill='#0279CB' />
                  <path
                     id='whitePart'
                     d='M9.99939 37.2019L147.575 8.57422V69.2309L9.99939 98.2423V37.2019Z'
                     fill='white'
                  />
                  <path
                     d='M145.153 11.5595V67.2586L12.4212 95.2479V39.1773L145.153 11.5595ZM150 5.58901L7.57455 35.2236V101.234L150 71.2001V5.58594V5.58901Z'
                     fill='#0279CB'
                  />
                  <path
                     d='M23.1431 47.438C28.2255 46.4526 30.687 45.9768 35.4816 45.0498C40.1537 43.8342 49.0142 43.6439 48.7325 50.7778C48.5733 54.7806 46.6108 58.851 42.6643 61.1041C47.5782 61.0581 50.4929 64.5514 50.3919 69.2203C50.2266 76.7257 42.2418 79.6848 35.8857 80.9802C30.9473 81.9779 28.4122 82.4874 23.186 83.5219C22.9288 71.495 23.3727 59.4649 23.1462 47.438H23.1431ZM35.1877 58.6423C37.885 58.0866 39.2781 56.6899 39.2934 54.7008C39.3087 52.7669 37.9738 52.1284 35.2765 52.7024C34.5111 52.8651 34.1253 52.948 33.3538 53.1107C33.3476 55.3761 33.3384 56.5825 33.317 59.029C34.0671 58.8725 34.4437 58.7957 35.1877 58.6423ZM33.2435 66.9273C33.219 69.7514 33.2068 71.1573 33.1976 73.7604C34.3427 73.5516 34.9091 73.4473 36.0388 73.2416C39.0576 72.6921 40.5732 71.3323 40.5915 69.1559C40.6099 66.869 39.0148 65.8253 36.0878 66.384C34.9581 66.5988 34.3886 66.7093 33.2405 66.9273H33.2435Z'
                     fill='#0279CB'
                  />
                  <path
                     d='M125.243 27.2172C135.837 25.271 140.034 34.13 140.034 42.4396C140.034 52.7752 136.011 61.3887 125.066 63.7063C115.008 65.8366 109.203 58.1471 109.418 48.6312C109.638 38.8481 114.88 29.1234 125.243 27.2202V27.2172ZM125.06 56.6768C128.792 55.9799 131.284 51.2619 131.345 44.0205C131.407 36.9664 129.018 33.424 125.25 34.2467C121.481 35.0694 118.894 39.6247 118.823 46.5591C118.75 53.6163 121.328 57.3736 125.06 56.6768Z'
                     fill='#0279CB'
                  />
                  <path
                     d='M66.6646 38.4672C77.258 36.521 81.4555 45.38 81.4555 53.6896C81.4555 64.0252 77.4325 72.6387 66.487 74.9563C56.4295 77.0866 50.6246 69.3971 50.8389 59.8812C51.0593 50.0981 56.3009 40.3734 66.6646 38.4702V38.4672ZM66.4809 67.9268C70.2131 67.2299 72.7053 62.5119 72.7665 55.2705C72.8277 48.2164 70.4397 44.674 66.6707 45.4967C62.9018 46.3194 60.3147 50.8748 60.2443 57.8091C60.1708 64.8663 62.7488 68.6236 66.4809 67.9268Z'
                     fill='#0279CB'
                  />
                  <path
                     d='M82.8914 35.8459C86.1429 35.2105 87.7472 34.8943 90.9344 34.2681L99.9173 49.8374C99.9388 44.0542 99.9816 38.2709 99.948 32.4908C103.089 31.8707 104.651 31.5606 107.774 30.9375C107.832 42.6022 107.682 54.267 107.749 65.9286C104.458 66.6162 102.808 66.9631 99.4918 67.663L90.8762 52.9839C90.8456 58.4878 90.7936 63.9917 90.8273 69.4987C87.6248 70.1771 86.0113 70.5209 82.7475 71.2115C82.6832 59.4209 82.9435 47.6334 82.8914 35.8428V35.8459Z'
                     fill='#0279CB'
                  />
               </g>
               <defs>
                  <clipPath id='clip0_10119_14936'>
                     <rect width='150' height='101.235' fill='white' />
                  </clipPath>
               </defs>
            </svg>
         </AnimatedLogoContainer>
      </AnimatedBox>
   );
}

export function CreatorFlowLayout(): React.ReactElement {
   const [isLoading, setIsLoading] = useState(true);
   const theme = useTheme();
   const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

   useLayoutEffect(() => {
      setTimeout(() => {
         setIsLoading(false);
      }, 2000);
   }, []);

   return (
      <>
         {isLoading && <CreatorFlowLoading />}
         <Header />
         <Container
            sx={{
               width: { xs: '100%', sm: '390px' },
               margin: '0 auto',
            }}
         >
            <Box
               sx={{
                  height: { xs: '100dvh', sm: 'calc(100vh - 60px)' },
                  width: '100%',
               }}
            >
               <Outlet />
            </Box>
         </Container>
      </>
   );
}

export function NoLayout(): React.ReactElement {
   return <Outlet />;
}
