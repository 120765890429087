import { Box, Button, TextField, Typography } from '@mui/material';

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledInput } from '../textField/CreatorFlowTextField';

export interface StepComponentProps {
   getHeight: (height: number) => void;
   setUserName: (name: string) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function NameStep({ getHeight, setUserName }: StepComponentProps): React.ReactElement {
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.

   const handleUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setUserName(e.target.value);
   };

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
            What should we call you today?
         </Typography>
         <Box
            sx={{
               width: '100%',
            }}
         >
            <StyledInput type='text' placeholder='Your Name' onChange={handleUserNameChange} />
         </Box>
      </Box>
   );
}
