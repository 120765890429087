import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IMAGE } from 'enums/images';

export interface StepComponentProps {
   userName: string;
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function Step3({ userName, getHeight }: StepComponentProps): React.ReactElement {
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            paddingBottom: '268px',
            gap: 3.75,
         }}
      >
         <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.7 }}
         >
            <Box
               component='img'
               alt='User Image'
               sx={{
                  width: '100px',
                  justifyContent: 'center',
               }}
               src={IMAGE.USER_IMAGE}
            />
         </motion.div>
         <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.7 }}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
         >
            <Typography
               sx={{ fontWeight: 900, textAlign: 'center', paddingBottom: '21px' }}
               variant='h4'
               component='h1'
            >
               Hi {userName}!
            </Typography>
            <Typography
               sx={{ fontWeight: 500, fontSize: 18, textAlign: 'center', padding: '0 36px' }}
               variant='h4'
               component='h4'
               color='#666666'
            >
               Let’s start by calculating the impact you can make this year with your community of followers
            </Typography>
         </motion.div>
      </Box>
   );
}
