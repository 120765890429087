import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
   Box,
   Button,
   Dialog,
   DialogContent,
   DialogContentText,
   DialogTitle,
   DialogActions,
   useMediaQuery,
   useTheme,
} from '@mui/material';
// import { authState } from 'store/auth/auth.slice';
// import { useAppSelector } from 'store/hooks';
// import { useTranslation } from 'react-i18next';
import { WelcomeStep } from 'components/creatorFlow/steps/WelcomeStep';
import { NameStep } from 'components/creatorFlow/steps/NameStep';
import { Step3 } from 'components/creatorFlow/steps/Step3';
import { SocialNetworkStep } from 'components/creatorFlow/steps/SocialNetworksStep';
import { FollowersStep } from 'components/creatorFlow/steps/FollowersStep';
import { ViewsStep } from 'components/creatorFlow/steps/ViewsStep';
import { SocialIssuesStep } from 'components/creatorFlow/steps/SocialIssuesStep';
import { CalculatingStep } from 'components/creatorFlow/steps/CalculatingStep';
import { PostStep } from 'components/creatorFlow/steps/PostStep';
import { PersonalImpactStep } from 'components/creatorFlow/steps/PersonalImpactStep';

export function CreatorFlowIntroPage() {
   // const { signupData } = useAppSelector(authState);
   // const { t } = useTranslation();
   const [buttonYPos, setButtonYPos] = useState(287);
   const [prevStep, setPrevStep] = useState(0);
   const [userName, setUserName] = useState('');
   const [currentStep, setCurrentStep] = useState(1);

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const handleBack = () => {
      setPrevStep(currentStep);
      setCurrentStep((prev) => {
         return prev - 1;
      });
   };

   const handleNext = () => {
      if (currentStep === 2 && userName === '') {
         setDialogOpen(true);
         return;
      }

      setPrevStep(currentStep);
      setCurrentStep((prev) => {
         return prev + 1;
      });
   };

   const handleAnimateFinish = () => {
      setPrevStep(0);
   };

   const getInitialPosition = (previousStep: number, currentStepNumber: number, currentComponenet: number) => {
      if (previousStep === 1 && currentStep === 2) return { opacity: 0 };
      if (previousStep === 2 && currentStep === 1) return { opacity: 1 };
      if (previousStep === currentComponenet) return { left: 0 };
      if (previousStep > currentStepNumber) return { left: '-390px' };
      return { left: '390px' };
   };

   const getAnimatedPosition = (previousStep: number, currentStepNumber: number, currentComponenet: number) => {
      if (previousStep === 1 && currentStep === 2) return { opacity: 1 };
      if (previousStep === 2 && currentStep === 1) return { opacity: 0 };
      if (currentStepNumber === currentComponenet) return { left: 0 };
      if (previousStep > currentStepNumber) return { left: '390px' };
      return { left: '-390px' };
   };

   const handleButtonYPos = (yPos: number) => {
      setButtonYPos(yPos);
   };

   const [dialogOpen, setDialogOpen] = React.useState(false);

   const handleDialogClose = () => {
      setDialogOpen(false);
   };

   return (
      <div
         style={{
            width: '100%',
            height: '100%',
            position: 'relative',
         }}
      >
         {currentStep < 10 && (
            <Box
               sx={{
                  opacity: { xs: 0, sm: 1 },
                  display: { xs: 'none', sm: 'block' },
                  width: '390px',
                  height: '100%',
                  left: '412px',
                  position: 'absolute',
                  background: '#f7f7f7',
                  zIndex: 100,
               }}
            />
         )}
         {currentStep < 10 && (
            <Box
               sx={{
                  opacity: { xs: 0, sm: 1 },
                  width: '390px',
                  display: { xs: 'none', sm: 'block' },
                  height: '100%',
                  left: '-412px',
                  position: 'absolute',
                  background: '#f7f7f7',
                  zIndex: 100,
               }}
            />
         )}
         {currentStep === 1 && <WelcomeStep isMobile={isMobile} onNext={handleNext} />}
         {(currentStep === 2 || prevStep === 2) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 2)}
               animate={getAnimatedPosition(prevStep, currentStep, 2)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <NameStep setUserName={setUserName} getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 3 || prevStep === 3) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 3)}
               animate={getAnimatedPosition(prevStep, currentStep, 3)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <Step3 userName={userName} getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 4 || prevStep === 4) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 4)}
               animate={getAnimatedPosition(prevStep, currentStep, 4)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <SocialNetworkStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 5 || prevStep === 5) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 5)}
               animate={getAnimatedPosition(prevStep, currentStep, 5)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <FollowersStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 6 || prevStep === 6) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 6)}
               animate={getAnimatedPosition(prevStep, currentStep, 6)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <ViewsStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 7 || prevStep === 7) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 7)}
               animate={getAnimatedPosition(prevStep, currentStep, 7)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <SocialIssuesStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 8 || prevStep === 8) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 8)}
               animate={getAnimatedPosition(prevStep, currentStep, 8)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <PostStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {currentStep === 9 && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 9)}
               animate={getAnimatedPosition(prevStep, currentStep, 9)}
               transition={{ duration: 0.5 }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <CalculatingStep isMobile={isMobile} onPrev={handleBack} onNext={handleNext} />
            </motion.div>
         )}
         {(currentStep === 10 || prevStep === 10) && <PersonalImpactStep onPrev={handleBack} onNext={handleNext} />}
         {currentStep > 1 && (
            <motion.div
               style={{
                  position: 'absolute',
                  width: '100%',
                  marginTop: buttonYPos,
               }}
               animate={currentStep > 8 ? { opacity: 0 } : { marginTop: buttonYPos, opacity: 1 }}
               transition={{ duration: 0.5 }}
            >
               <Box
                  sx={{
                     position: { xs: 'fixed', sm: 'relative' },
                     bottom: { xs: 0 },
                     height: { xs: '98px', sm: 'unset' },
                     borderTop: { xs: '1px solid #00000016', sm: 'unset' },
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: { xs: '100%', sm: '100%' },
                     padding: { xs: '0px 30px', sm: '0px 0px' },
                     margin: { xs: '0px -30px', sm: '0px 0px' },
                  }}
               >
                  <Button
                     sx={{ mt: { sm: 5.25 }, justifyContent: 'end' }}
                     className='creatorFlow'
                     variant='text'
                     size='medium'
                     color='black'
                     onClick={handleBack}
                  >
                     Back
                  </Button>

                  <Button
                     sx={{ mt: { sm: 5.25 }, justifyContent: 'end' }}
                     className='creatorFlow'
                     variant='contained'
                     size='medium'
                     color='black'
                     onClick={() => handleNext()}
                  >
                     Next
                  </Button>
               </Box>
            </motion.div>
         )}

         <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
               <DialogContentText>Please type your name.</DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleDialogClose}>OK</Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
